var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./inflections.core.js");require("./metabase.lib.card.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.filter.js");require("./metabase.lib.filter.operator.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.query.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./cljs.core.match.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var Vqa,Wqa,Xqa,Yqa,k3,l3,m3,n3,Zqa,$qa,ara,bra,cra,dra,era,fra,gra,x3,y3,hra,ira,jra,w3,v3,kra,z3;$CLJS.e3=function(a,b){$CLJS.cb(function(c,d){return a.h?a.h(d):a.call(null,d)},null,b)};
Vqa=function(a){return function(b){var c=$CLJS.Ve(!0);return function(){function d(l,m){var t=$CLJS.q(c);if($CLJS.n($CLJS.n(t)?a.h?a.h(m):a.call(null,m):t))return l;$CLJS.el(c,null);return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=
f;k.h=e;k.g=d;return k}()}};Wqa=function(a,b){return function f(d,e){return new $CLJS.ie(null,function(){var k;a:{var l=d;for(k=e;;){var m=l;l=$CLJS.I(m,0,null);if(m=$CLJS.y(m)){var t=a.h?a.h(l):a.call(null,l);if($CLJS.Cd(k,t))l=$CLJS.Hc(m);else{k=$CLJS.ae(l,f($CLJS.Hc(m),$CLJS.Yd.g(k,t)));break a}}else{k=null;break a}}}return k},null,null)}(b,$CLJS.Sg)};
Xqa=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;c=arguments[0];d=arguments[1];var e=arguments[2];b=3<b.length?new $CLJS.w(b.slice(3),0,null):null;return $CLJS.Ra($CLJS.y($CLJS.Bz(c,d)))?c:$CLJS.Ne.M($CLJS.yW,c,d,e,b)};$CLJS.f3=function(a){return(0,$CLJS.ma)($CLJS.Gz(a,$CLJS.Wma,""))};$CLJS.g3=function(a,b){return $CLJS.t0.g(a,b instanceof $CLJS.M?b:$CLJS.di.h(b))};
$CLJS.h3=function(a,b,c,d){if($CLJS.y(c)){d=$CLJS.ck.g($CLJS.sV,d);var e=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h(function(f){return $CLJS.U1.v(a,b,f,c)}),d);return $CLJS.ck.g(function(f){return $CLJS.R.j(f,$CLJS.NY,$CLJS.Cd(e,f))},c)}return null};$CLJS.i3=function(a){return $CLJS.E.g($CLJS.XA(a),$CLJS.mK)};Yqa=function(a,b){return $CLJS.n($CLJS.QD.h(a))?$CLJS.dk.j(a,$CLJS.QD,function(c){return $CLJS.rd(c)?$CLJS.ck.g(function(d){return $CLJS.j3.g?$CLJS.j3.g(d,b):$CLJS.j3.call(null,d,b)},c):c}):a};
k3=function(a){if($CLJS.n(a)){var b=$CLJS.ff;a:try{if($CLJS.ud(a)&&4===$CLJS.D(a))try{if($CLJS.F(a,0)instanceof $CLJS.M)try{var c=$CLJS.F(a,2);if($CLJS.ud(c)&&3===$CLJS.D(c))try{var d=$CLJS.F(c,0);if($CLJS.ce(d,$CLJS.iF))try{var e=$CLJS.F(a,3);if($CLJS.ud(e)&&3===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.ce(f,$CLJS.iF)){$CLJS.F(e,1);$CLJS.F(e,2);$CLJS.F(c,1);$CLJS.F(c,2);$CLJS.F(a,0);$CLJS.F(a,1);var k=new $CLJS.P(null,1,5,$CLJS.Q,[!0],null)}else throw $CLJS.Z;}catch(m){if(m instanceof Error){var l=
m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof
Error){l=m;if(l===$CLJS.Z){k=new $CLJS.P(null,1,5,$CLJS.Q,[!1],null);break a}throw l;}throw m;}a=$CLJS.z($CLJS.y(b($CLJS.Sa,k)))}else a=null;return a};l3=function(a){if($CLJS.n(k3(a))){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);$CLJS.I(a,3,null);return b}return null};m3=function(a){return $CLJS.n(k3(a))?($CLJS.I(a,0,null),$CLJS.I(a,1,null),$CLJS.I(a,2,null),$CLJS.I(a,3,null)):null};
n3=function(a,b){var c=$CLJS.j3;if($CLJS.Ra(k3(a)))return a;var d=$CLJS.I(a,0,null),e=$CLJS.I(a,1,null),f=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[d,e,f,$CLJS.Ne.j(c,a,b)],null)};
Zqa=function(a,b,c){return $CLJS.od($CLJS.uK.h(a))?a:$CLJS.n(b)?Xqa(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.uK],null),function(d){return function l(f,k){try{if($CLJS.ud(k)&&3===$CLJS.D(k))try{var m=$CLJS.F(k,0);if($CLJS.ce(m,$CLJS.iF))try{var t=$CLJS.F(k,1);if(null!=t?t.C&256||$CLJS.yc===t.mf||(t.C?0:$CLJS.Ya($CLJS.ub,t)):$CLJS.Ya($CLJS.ub,t))try{var u=$CLJS.J.j(t,$CLJS.wO,$CLJS.FU);if($CLJS.E.g(u,b))return $CLJS.F(k,2),$CLJS.j3.g?$CLJS.j3.g(k,c):$CLJS.j3.call(null,k,c);throw $CLJS.Z;}catch(x){if(x instanceof
Error){var v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)return $CLJS.$V(l,f,k);throw v;}throw x;}}($CLJS.xf,d)}):$CLJS.dk.j(a,$CLJS.uK,function(d){return $CLJS.ck.g(function(e){return n3(e,$CLJS.H([c]))},d)})};
$CLJS.j3=function(a,b){var c=$CLJS.XA(a);switch(c instanceof $CLJS.M?c.S:null){case "field":return $CLJS.ED(a,$CLJS.CD,$CLJS.H([$CLJS.wO,b]));case "metadata/column":return $CLJS.CD(a,$CLJS.fZ,b);case "mbql/join":return c=$CLJS.i1(a),Zqa(Yqa($CLJS.CD(a,$CLJS.UD,b),b),c,b);default:return b=$CLJS.Mz($CLJS.Cx),$CLJS.n($CLJS.Lz("metabase.lib.join",b))&&$CLJS.Kz("metabase.lib.join",b,$CLJS.xv.l($CLJS.H(["with-join-value should not be called with",$CLJS.xh.l($CLJS.H([a]))])),null),a}};
$CLJS.o3=function(a,b,c){var d=$CLJS.R.l,e=$CLJS.zz($CLJS.xY,$CLJS.U)(c);a=$CLJS.j1($CLJS.UD.h(a),$CLJS.zz($CLJS.xY,$CLJS.U)(c));b=b.h?b.h(a):b.call(null,a);return d.call($CLJS.R,c,$CLJS.xY,e,$CLJS.H([$CLJS.MY,b]))};$qa=function(a,b){return $CLJS.Ra(b)?a:$CLJS.ck.g(function(c){var d=m3(c);d=$CLJS.n(d)?$CLJS.n($CLJS.i1(d))?null:n3(c,$CLJS.H([b])):null;return $CLJS.n(d)?d:c},a)};$CLJS.p3=function(a,b){b=$qa($CLJS.ck.g($CLJS.tV,b),$CLJS.i1(a));return $CLJS.CD(a,$CLJS.uK,$CLJS.Be(b))};
$CLJS.q3=function(a,b){b=b instanceof $CLJS.M?b:$CLJS.E.g(b,$CLJS.xf)?$CLJS.cz:$CLJS.Be($CLJS.Wf.j($CLJS.xf,$CLJS.ak.g($CLJS.cf.h($CLJS.sV),function(){var c=$CLJS.i1(a);return $CLJS.n(c)?$CLJS.cf.h(function(d){return $CLJS.j3(d,c)}):$CLJS.Pd}()),b));return $CLJS.CD(a,$CLJS.QD,b)};
ara=function(a,b){b=$CLJS.Bl(function(d){return $CLJS.U1.g(d,a)},b);var c=$CLJS.rG($CLJS.zz($CLJS.KZ,$CLJS.LZ),b);if($CLJS.n(c))return c;b=$CLJS.z(b);if($CLJS.n(b))return b;b=$CLJS.rG($CLJS.KZ,a);if($CLJS.n(b))return b;b=$CLJS.rG($CLJS.LZ,a);return $CLJS.n(b)?b:$CLJS.z(a)};bra=function(a){return"string"===typeof a?(0,$CLJS.ma)($CLJS.Gz(a,/ id$/i,"")):null};
cra=function(a,b){var c="string"===typeof a;if(c){if(c="string"===typeof b){c=new $CLJS.uh(function(){return $CLJS.p2(b)});var d=new $CLJS.uh(function(){return $CLJS.p2(a)});return $CLJS.E.g(a,b)||$CLJS.E.g(a,$CLJS.q(c))||$CLJS.E.g($CLJS.q(d),b)||$CLJS.E.g($CLJS.q(d),$CLJS.q(c))}return c}return c};
dra=function(a,b,c){var d=$CLJS.l_.g($CLJS.E.g($CLJS.Vs.h(b),$CLJS.jV)?b:a,b),e=$CLJS.n(c)?bra($CLJS.l_.g(a,c)):null,f=cra(d,e);return function(){var k=function(){if($CLJS.n(d)){if($CLJS.n(e)){var l=$CLJS.Ra($CLJS.dh(/id/i,e));return l?(l=!f)?[$CLJS.p.h(d)," - ",e].join(""):l:l}return e}return d}();return $CLJS.n(k)?k:$CLJS.n(d)?d:$CLJS.n(e)?e:"source"}()};
era=function(a,b,c,d,e){return function m(k,l){try{if($CLJS.H1(l,$CLJS.iF)&&$CLJS.zd($CLJS.U1.v(a,b,l,e)))return $CLJS.j3(l,d);throw $CLJS.Z;}catch(u){if(u instanceof Error){var t=u;if(t===$CLJS.Z)return $CLJS.$V(m,k,l);throw t;}throw u;}}($CLJS.xf,c)};
fra=function(a,b,c,d,e,f){c=era(a,b,c,d,f);return function t(l,m){try{if($CLJS.ud(m)&&4===$CLJS.D(m))try{var u=$CLJS.F(m,2);if($CLJS.H1(u,$CLJS.iF))try{var v=$CLJS.F(m,3);if($CLJS.H1(v,$CLJS.iF)){var x=$CLJS.F(m,3),A=$CLJS.F(m,2),C=$CLJS.F(m,0),G=$CLJS.F(m,1),K=$CLJS.i1(A),S=$CLJS.i1(x);if($CLJS.Ra($CLJS.n(K)?K:S))return $CLJS.n($CLJS.U1.v(a,b,x,e))?new $CLJS.P(null,4,5,$CLJS.Q,[C,G,$CLJS.j3(A,d),x],null):new $CLJS.P(null,4,5,$CLJS.Q,[C,G,A,$CLJS.j3(x,d)],null);if($CLJS.E.g(K,d)&&$CLJS.E.g(S,d)){var X=
$CLJS.ED(A,$CLJS.Tj,$CLJS.H([$CLJS.wO])),T=$CLJS.ED(x,$CLJS.Tj,$CLJS.H([$CLJS.wO])),da=null==$CLJS.U1.v(a,b,X,e);var oa=da?$CLJS.U1.v(a,b,T,e):da;return $CLJS.n(oa)?new $CLJS.P(null,4,5,$CLJS.Q,[C,G,A,T],null):new $CLJS.P(null,4,5,$CLJS.Q,[C,G,X,x],null)}return m}throw $CLJS.Z;}catch(sb){if(sb instanceof Error){var Ma=sb;if(Ma===$CLJS.Z)throw $CLJS.Z;throw Ma;}throw sb;}else throw $CLJS.Z;}catch(sb){if(sb instanceof Error){Ma=sb;if(Ma===$CLJS.Z)throw $CLJS.Z;throw Ma;}throw sb;}else throw $CLJS.Z;
}catch(sb){if(sb instanceof Error){Ma=sb;if(Ma===$CLJS.Z)return $CLJS.$V(t,l,m);throw Ma;}throw sb;}}($CLJS.xf,c)};gra=function(a,b){var c=$CLJS.KV();$CLJS.e3(c,b);return c(a)};
$CLJS.r3=function(a,b,c){if($CLJS.Cd(c,$CLJS.UD))return c;var d=$CLJS.HV(a,b),e=$CLJS.Y_.j(a,b,d),f=$CLJS.y($CLJS.ff($CLJS.Sa,function v(t,u){try{if($CLJS.ud(u)&&1<=$CLJS.D(u))try{var x=$CLJS.hk.j(u,0,1);if($CLJS.ud(x)&&1===$CLJS.D(x))try{var A=$CLJS.F(x,0);if($CLJS.ce(A,$CLJS.iF))return new $CLJS.P(null,1,5,$CLJS.Q,[u],null);throw $CLJS.Z;}catch(G){if(G instanceof Error){var C=G;if(C===$CLJS.Z)throw $CLJS.Z;throw C;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){C=G;if(C===$CLJS.Z)throw $CLJS.Z;
throw C;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){C=G;if(C===$CLJS.Z)return $CLJS.CU(v,t,u);throw C;}throw G;}}($CLJS.xf,$CLJS.uK.h(c))));f=ara(e,f);var k=gra(dra(a,c,f),$CLJS.Bl($CLJS.UD,$CLJS.GM.h(d))),l=$CLJS.W_.h($CLJS.c1.g(a,$CLJS.rK.h(c)));return $CLJS.j3($CLJS.dk.j(c,$CLJS.uK,function(t){return $CLJS.ck.g(function(u){return fra(a,b,u,k,e,l)},t)}),k)};$CLJS.s3=function(a){return $CLJS.uK.h(a)};
$CLJS.t3=function(a){return $CLJS.sk.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.vK,$CLJS.AD,a],null),$CLJS.E.g(a,$CLJS.ZD)?new $CLJS.h(null,1,[$CLJS.Oh,!0],null):null]))};$CLJS.u3=function(a,b){b=$CLJS.z($CLJS.rK.h(b));return $CLJS.n($CLJS.QU.h(b))?$CLJS.f_(a,$CLJS.QU.h(b)):$CLJS.n($CLJS.hO.h(b))?$CLJS.d_(a,$CLJS.hO.h(b)):null};
x3=function(a){a=$CLJS.qG(function(d){return $CLJS.LZ(d)?v3:$CLJS.KZ(d)?w3:$CLJS.Rs},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,v3);var c=$CLJS.J.g(b,w3);b=$CLJS.J.g(b,$CLJS.Rs);return $CLJS.bf.l(a,c,$CLJS.H([b]))};y3=function(a,b,c,d){return $CLJS.Ra(c)?d:$CLJS.ck.g(function(e){return $CLJS.n($CLJS.NY.h(e))?$CLJS.g3(e,$CLJS.n2(c)):e},$CLJS.h3(a,b,d,new $CLJS.P(null,1,5,$CLJS.Q,[c],null)))};
hra=function(a,b,c,d){var e=new $CLJS.uh(function(){return $CLJS.Y_.v(a,b,d,new $CLJS.h(null,2,[$CLJS.B_,!1,$CLJS.J_,!1],null))});return $CLJS.Be($CLJS.Wf.j($CLJS.xf,$CLJS.vl(function(f){var k=$CLJS.O(f),l=$CLJS.J.g(k,$CLJS.XJ);return $CLJS.n(function(){var m=$CLJS.KZ(k);return m?l:m}())?(f=$CLJS.rG(function(m){return $CLJS.E.g(l,$CLJS.Fi.h(m))},$CLJS.q(e)),$CLJS.n(f)?$CLJS.R.j(k,z3,f):null):null}),$CLJS.Y_.v(a,b,c,new $CLJS.h(null,2,[$CLJS.B_,!1,$CLJS.J_,!1],null))))};ira={};
jra=new $CLJS.M("metabase.lib.join","join-fields","metabase.lib.join/join-fields",-2085751377);w3=new $CLJS.M(null,"fk","fk",398442651);v3=new $CLJS.M(null,"pk","pk",-771936732);kra=new $CLJS.r("metabase.lib.stage","ensure-previous-stages-have-metadata","metabase.lib.stage/ensure-previous-stages-have-metadata",2048342183,null);z3=new $CLJS.M("metabase.lib.join","target","metabase.lib.join/target",203858838);$CLJS.M_.m(null,$CLJS.mK,function(a,b,c){b=$CLJS.O(c);b=$CLJS.J.g(b,$CLJS.rK);b=$CLJS.I(b,0,null);b=$CLJS.O(b);c=$CLJS.J.g(b,$CLJS.hO);b=$CLJS.J.g(b,$CLJS.QU);c=$CLJS.n(c)?$CLJS.kD.h($CLJS.d_(a,c)):null;if($CLJS.n(c))return c;$CLJS.n(b)?(c=$CLJS.f_(a,b),a=$CLJS.n(c)?$CLJS.l_.j(a,0,c):$CLJS.n1(b)):a=null;return $CLJS.n(a)?a:$CLJS.HD("Native Query")});$CLJS.U_.m(null,$CLJS.mK,function(a,b,c){a=$CLJS.l_.j(a,b,c);c=$CLJS.UD.h(c);return new $CLJS.h(null,2,[$CLJS.U,$CLJS.n(c)?c:a,$CLJS.kD,a],null)});
$CLJS.T_.m(null,$CLJS.mK,function(){throw $CLJS.Uh("You can't calculate a metadata map for a join! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.M_.m(null,$CLJS.vK,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.AD);a=a instanceof $CLJS.M?a.S:null;switch(a){case "left-join":return $CLJS.HD("Left outer join");case "right-join":return $CLJS.HD("Right outer join");case "inner-join":return $CLJS.HD("Inner join");case "full-join":return $CLJS.HD("Full outer join");default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});
$CLJS.U_.m(null,$CLJS.vK,function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.AD);c=$CLJS.J.g(d,$CLJS.Oh);a=new $CLJS.h(null,2,[$CLJS.K_,$CLJS.$z(e),$CLJS.kD,$CLJS.l_.j(a,b,d)],null);return $CLJS.n(c)?$CLJS.R.j(a,$CLJS.Oh,!0):a});
$CLJS.V_.m(null,$CLJS.mK,function(a,b,c,d){var e=$CLJS.O(c),f=$CLJS.J.g(e,$CLJS.UD),k=$CLJS.J.j(e,$CLJS.QD,$CLJS.cz),l=$CLJS.J.g(e,$CLJS.rK);c=$CLJS.O(d);var m=$CLJS.J.g(c,$CLJS.H_);if($CLJS.E.g(k,$CLJS.cz))return null;var t="undefined"!==typeof $CLJS.iL&&"undefined"!==typeof ira&&"undefined"!==typeof $CLJS.lra&&"undefined"!==typeof $CLJS.A3?new $CLJS.Cc(function(){return $CLJS.A3},$CLJS.kd(kra,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),null):null,u=function(){var v=$CLJS.R.j(a,$CLJS.rK,l);return $CLJS.n(t)?
t.g?t.g(v,-1):t.call(null,v,-1):v}();c=$CLJS.E.g(k,$CLJS.wx)?$CLJS.W_.v(u,-1,$CLJS.md(l),c):function(){return function A(x){return new $CLJS.ie(null,function(){for(;;){var C=$CLJS.y(x);if(C){if($CLJS.vd(C)){var G=$CLJS.ic(C),K=$CLJS.D(G),S=$CLJS.le(K);a:for(var X=0;;)if(X<K){var T=$CLJS.hd(G,X);T=$CLJS.ED(T,$CLJS.Tj,$CLJS.H([$CLJS.wO]));T=$CLJS.j_.j(u,-1,T);S.add(T);X+=1}else{G=!0;break a}return G?$CLJS.oe($CLJS.qe(S),A($CLJS.jc(C))):$CLJS.oe($CLJS.qe(S),null)}S=$CLJS.z(C);S=$CLJS.ED(S,$CLJS.Tj,$CLJS.H([$CLJS.wO]));
return $CLJS.ae($CLJS.j_.j(u,-1,S),A($CLJS.Hc(C)))}return null}},null,null)}(k)}();return $CLJS.ck.g(function(v){v=$CLJS.R.j(v,$CLJS.$Y,f);v=$CLJS.j3($CLJS.R.l(v,$CLJS.kD,$CLJS.l_.j(a,b,v),$CLJS.H([$CLJS.LJ,$CLJS.JY])),f);return $CLJS.o3(e,m,v)},c)});$CLJS.X_.m(null,$CLJS.mK,function(a,b,c,d){return $CLJS.W_.v(a,b,$CLJS.R.j(c,$CLJS.QD,$CLJS.wx),d)});
var B3,mra=$CLJS.Se($CLJS.N),nra=$CLJS.Se($CLJS.N),ora=$CLJS.Se($CLJS.N),pra=$CLJS.Se($CLJS.N),qra=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Ni,$CLJS.gB],null),$CLJS.Ni,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));B3=new $CLJS.Ph($CLJS.qh.g("metabase.lib.join","join-clause-method"),$CLJS.XA,qra,mra,nra,ora,pra);B3.m(null,$CLJS.mK,function(a){return a});B3.m(null,$CLJS.jV,function(a){return $CLJS.rV(new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.mK,$CLJS.rK,$CLJS.rK.h($CLJS.FV(a))],null))});
B3.m(null,$CLJS.XU,function(a){return $CLJS.rV(new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.mK,$CLJS.rK,new $CLJS.P(null,1,5,$CLJS.Q,[a],null)],null))});B3.m(null,$CLJS.iK,function(a){return $CLJS.rV(new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.mK,$CLJS.rK,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.QU,$CLJS.Fi.h(a),$CLJS.Vs,$CLJS.XU],null)],null)],null))});
B3.m(null,$CLJS.RJ,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.fZ);a=$CLJS.J.g(b,jra);b=B3.h(new $CLJS.h(null,3,[$CLJS.Vs,$CLJS.XU,$CLJS.uA,new $CLJS.h(null,1,[$CLJS.wD,$CLJS.p.h($CLJS.GD())],null),$CLJS.hO,$CLJS.Fi.h(b)],null));c=$CLJS.n(c)?$CLJS.j3(b,c):b;return $CLJS.n(a)?$CLJS.q3.g?$CLJS.q3.g(c,a):$CLJS.q3.call(null,c,a):c});
$CLJS.C3=function(){function a(d,e){return $CLJS.p3(c.h?c.h(d):c.call(null,d),e)}function b(d){return $CLJS.F1(B3.h(d),$CLJS.QD,$CLJS.wx)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.rra=function(){function a(d,e,f){var k=$CLJS.C3.h(f);f=$CLJS.od($CLJS.s3.h?$CLJS.s3.h(k):$CLJS.s3.call(null,k))?function(){var m=$CLJS.u3.g?$CLJS.u3.g(d,k):$CLJS.u3.call(null,d,k);return $CLJS.D3.j?$CLJS.D3.j(d,e,m):$CLJS.D3.call(null,d,e,m)}():null;f=$CLJS.y(f)?$CLJS.p3(k,f):k;var l=$CLJS.r3(d,e,f);return $CLJS.SV.l(d,e,$CLJS.dk,$CLJS.H([$CLJS.GM,function(m){return $CLJS.Yd.g($CLJS.yf(m),l)}]))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.E3=function(){function a(d,e){return $CLJS.Be($CLJS.J.g($CLJS.HV(d,e),$CLJS.GM))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.sra=function(){function a(d){d=$CLJS.Z0(d);d=$CLJS.dz.h(d);return $CLJS.Wf.j($CLJS.xf,$CLJS.ak.g($CLJS.ef($CLJS.Qe($CLJS.Cd,d)),$CLJS.cf.h($CLJS.t3)),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ZD,$CLJS.PD,$CLJS.cE,$CLJS.fE],null))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.tra=function(){function a(d,e,f,k){var l=$CLJS.i3(f)?$CLJS.i1(f):null,m=$CLJS.Wf.j($CLJS.Sg,$CLJS.ak.g($CLJS.cf.h($CLJS.i1),Vqa(function(t){return $CLJS.Zj.g(t,l)})),$CLJS.E3.g(d,e));f=$CLJS.n(k)?k:$CLJS.i3(f)?l3($CLJS.z($CLJS.s3(f))):null;return x3(y3(d,e,f,$CLJS.bk.g(function(t){t=$CLJS.i1(t);return $CLJS.n(t)?$CLJS.Cd(m,t):null},$CLJS.Y_.v(d,e,$CLJS.HV(d,e),new $CLJS.h(null,1,[$CLJS.B_,!1],null)))))}function b(d,e,f,k){return c.M?c.M(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.M=a;return c}();
$CLJS.ura=function(){function a(d,e,f,k,l){k=$CLJS.i3(f)?$CLJS.u3(d,f):f;var m=$CLJS.i3(f)?$CLJS.i1(f):null;f=$CLJS.n(l)?l:$CLJS.i3(f)?m3($CLJS.z($CLJS.s3(f))):null;f=$CLJS.n(f)?$CLJS.Ra(m)?$CLJS.ED(f,$CLJS.Tj,$CLJS.H([$CLJS.wO])):f:null;return x3(y3(d,e,f,$CLJS.cf.g(function(t){t=$CLJS.R.j(t,$CLJS.LJ,$CLJS.JY);return $CLJS.n(m)?$CLJS.j3(t,m):t},$CLJS.Y_.v(d,e,k,new $CLJS.h(null,1,[$CLJS.B_,!1],null)))))}function b(d,e,f,k){return c.M?c.M(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.M=a;return c}();$CLJS.vra=function(){function a(c,d,e){return b.v?b.v(c,-1,d,e):b.call(null,c,-1,d,e)}var b=null;b=function(c,d,e,f){switch(arguments.length){case 3:return a.call(this,c,d,e);case 4:return $CLJS.$1}throw Error("Invalid arity: "+arguments.length);};b.j=a;b.v=function(){return $CLJS.$1};return b}();
$CLJS.D3=function(){function a(d,e,f){function k(u,v){u=$CLJS.Tj.g(u,$CLJS.p1);v=$CLJS.Tj.g(v,$CLJS.p1);return $CLJS.c3.l($CLJS.X1.h($CLJS.Iq),u,$CLJS.H([v]))}function l(u,v){return $CLJS.Be(Wqa(function(x){return $CLJS.Fi.h(z3.h(x))},hra(d,e,u,v)))}var m=$CLJS.HV(d,e),t=function(){var u=l(m,f);return $CLJS.n(u)?$CLJS.ck.g(function(v){return k(v,z3.h(v))},u):null}();if($CLJS.n(t))return t;t=l(f,m);return $CLJS.n(t)?$CLJS.ck.g(function(u){return k(z3.h(u),u)},t):null}function b(d,e){return c.j?c.j(d,
-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.wra=$CLJS.cf.h(function(a){return $CLJS.R.j(a,$CLJS.LJ,$CLJS.JY)});
$CLJS.xra=function(){function a(e,f,k,l){l=$CLJS.n(l)?l:$CLJS.i3(k)?l3($CLJS.z($CLJS.s3(k))):null;$CLJS.n(l)?(l=$CLJS.o_.j(e,f,l),l=$CLJS.Bz(l,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,$CLJS.kD],null))):l=null;if($CLJS.n(l))return l;if(l=0===$CLJS.GV(e,f)){l=$CLJS.E3.g(e,f);var m=$CLJS.od(l);k=m?m:$CLJS.i3(k)?$CLJS.E.g($CLJS.UD.h(k),$CLJS.UD.h($CLJS.z(l))):null;k=$CLJS.n(k)?$CLJS.ZZ(e):k}else k=l;$CLJS.n(k)?(k=$CLJS.ZZ(e),k=$CLJS.d_(e,k),e=$CLJS.l_.j(e,f,k)):e=null;return $CLJS.n(e)?e:$CLJS.HD("Previous results")}
function b(e,f,k){return d.v?d.v(e,-1,f,k):d.call(null,e,-1,f,k)}function c(e,f){return d.j?d.j(e,f,null):d.call(null,e,f,null)}var d=null;d=function(e,f,k,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,k);case 4:return a.call(this,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.j=b;d.v=a;return d}();
$CLJS.yra=function(){function a(d,e,f,k){f=$CLJS.tV.h(f);$CLJS.I(f,0,null);$CLJS.I(f,1,null);var l=$CLJS.I(f,2,null),m=$CLJS.I(f,3,null);k=k instanceof $CLJS.M?k:$CLJS.di.h(k);e=$CLJS.GV(d,e);l=$CLJS.x0.j(d,e,l);d=$CLJS.x0.j(d,e,m);m=null==k||$CLJS.Cd($CLJS.Wg($CLJS.cf.g($CLJS.di,l)),k);d=null==k||$CLJS.Cd($CLJS.Wg($CLJS.cf.g($CLJS.di,d)),k);f=m?$CLJS.dk.v(f,2,$CLJS.g3,k):f;return d?$CLJS.dk.v(f,3,$CLJS.g3,k):f}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,
e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();$CLJS.P_.m(null,$CLJS.GM,function(a,b){var c=$CLJS.Be($CLJS.E3.g(a,b));c=null==c?null:$CLJS.cf.g(function(d){return $CLJS.l_.j(a,b,d)},c);return null==c?null:$CLJS.hs(" + ",c)});